<template>
	<div class="gonghai" @click="hideBtnBox">
		<div class="bumengbox">
			<div class="addbtn"></div>
			<el-tree
				:data="data"
				:props="defaultProps"
				@node-click="handleNodeClick"
				:expand-on-click-node="false"
			>
				<span class="custom-tree-node" slot-scope="{ node, data }">
					<span class="nodespan">{{ node.label }} ({{ node.data.level ? node.data.totalNumber : allnumber }})</span>
					<span class="addicon">
						<i class="el-icon-circle-plus-outline" @click.stop="showBtn(node.data)"></i>
					</span>
					<div class="hidebtnbox" v-if="node.data.level" :class="{ showbtnbox: showId === node.data.departmentId }">
						<div
							@click.stop="showEdit(node.data)"
							v-if="permissionList.includes('bumengManage:edit') || isAdmin"
						>
							编辑部门
						</div>
						<div
							@click.stop="showAdd(node.data)"
							v-if="permissionList.includes('bumengManage:add') || isAdmin"
						>
							新建下级部门
						</div>
						<div @click.stop="showDelete(node.data.departmentId)" v-if="isAdmin">删除部门</div>
					</div>
					<div class="hidebtnbox" v-else :class="{ showbtnbox: showId === node.data.departmentId }">
						<div
							@click.stop="showAdd()"
							v-if="permissionList.includes('bumengManage:add') || isAdmin"
						>
							新建部门
						</div>
						
					</div>
				</span>
			</el-tree>
		</div>
		<div class="" style="width: 82%;"v-if="permissionList.includes('zhanghaoManage') || isAdmin">
			<div class="tablebox">
				<div class="box123">
					<div class="tabletop">
						<div class="inputbox">
							<input v-model="searchInfo.searchBody" placeholder="关键词" />
							<div class="flex"><i class="el-icon-search" style="color: #AAAAAA;"></i></div>
						</div>
						<!-- 	<div class="selectbox">
							<span>用户状态</span>
							<el-select v-model="company" placeholder="请选择">
								<el-option
									v-for="item in companyList"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								></el-option>
							</el-select>
						</div> -->
						<!-- 	<div class="selectbox">
							<span>用户角色</span>
							<el-select v-model="company" placeholder="请选择">
								<el-option
									v-for="item in companyList"
									:key="item.value"
									:label="item.label"
									:value="item.value"
								></el-option>
							</el-select>
						</div> -->
						<!-- 	<div class="selectbox">
							<span>创建日期</span>
							<el-date-picker v-model="value1" type="date" placeholder="选择日期"></el-date-picker>
						</div> -->
						<!-- <el-button class="chaxun flex">查询</el-button> -->
						<div class="chaxun nochoosetext flex" @click="_staffList" style="margin-left: 2.5em;">
							<span>
								查询
							</span>
						</div>
						<!-- <el-button class="chongzhi flex">重置</el-button> -->
					</div>
					<div
						class="addbox"
						@click="showAdd1"
						v-if="permissionList.includes('zhanghaoManage:add') || isAdmin"
					>
						<i class="el-icon-plus" style="color: #ffffff;"></i>
						<span>新增</span>
					</div>
				</div>

				<div style="margin: 0.9375em 0 0;">
					<el-table :data="tableData" border style="width: 100%">
						<el-table-column type="index" label="序号"></el-table-column>
						<el-table-column prop="loginName" label="账号"></el-table-column>
						<el-table-column prop="realName" label="姓名"></el-table-column>

						<el-table-column prop="mobile" label="手机号"></el-table-column>
						<el-table-column prop="roleName" label="所属角色"></el-table-column>
						<el-table-column prop="departmentName" label="所属部门"></el-table-column>
						<!-- <el-table-column prop="roleName" label="职务"></el-table-column> -->
						<el-table-column prop="createdTime" label="创建时间"></el-table-column>

						<el-table-column label="操作" width="150">
							<template slot-scope="scope">
								<div class="twobtn">
									<div
									 class="kanbox gai"
										@click="showEdit1(scope.row)"
										v-if="permissionList.includes('zhanghaoManage:edit') || isAdmin"
									>
										<img src="@/assets/btnicon/image3.png" />
										改
									</div>

									<div class="kanbox shan" @click="showDelete1(scope.row.userId)" v-if="isAdmin">
										<img src="@/assets/btnicon/image6.png" />
										删
									</div>
								</div>
							</template>
						</el-table-column>
					</el-table>

					<div class="pagebox">
						<el-pagination
							background
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="searchInfo.page"
							:page-sizes="[5, 10, 15, 20]"
							:page-size="searchInfo.pageSize"
							layout="total, sizes, prev, pager, next, jumper"
							:total="totalCount"
						></el-pagination>
					</div>
				</div>
			</div>
			<delete-zhanghao
				@deleteInfo="_deleteStaff"
				message="确认要删除账号吗？"
				ref="deleteZhanghao"
			></delete-zhanghao>
			<add-zhanghao @updataInfo="updataZhanghao" ref="addZhanghao"></add-zhanghao>
			<edit-zhanghao @updataInfo="updataZhanghao" ref="editZhanghao"></edit-zhanghao>
		</div>

		<delete-popup
			@deleteInfo="_deleteDepartment"
			ref="deletePopup"
			message="是否删除部门信息"
		></delete-popup>
		<add-bumeng @updataInfo="updataBumeng" ref="addBumeng"></add-bumeng>
		<edit-bumeng @updataInfo="updataBumeng" ref="editBumeng"></edit-bumeng>
	</div>
</template>

<script>
import {
	departmentList,
	deleteDepartment,
	departmentTree,
	staffList,
	deleteStaff,
	roleList
} from '@/network/api.js';
import deletePopup from '../../../components/common/deletePopup.vue';
import addBumeng from '../popup/addBumeng.vue';
import editBumeng from '../popup/editBumeng.vue';
import deleteZhanghao from '../../../components/common/deletePopup.vue';
import addZhanghao from '../popup/addZhanghao.vue';
import editZhanghao from '../popup/editZhanghao.vue';
export default {
	data() {
		return {
			value1: '',
			totalCount: 0,
			deleteId: '',
			companyList: [
				{
					value: '阿里',
					lable: '阿里'
				}
			],
			searchInfo: {
				disabled: '',
				endDate: '',
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				roleId: '',
				searchBody: '',
				startDate: '',
				level: '',
				departmentId: ''
			},
			allnumber: '',
			showId: '',
			data: [
				{
					departmentName: '所有部门',
					level: '',
					departmentId: '1',
					departmentChildren: []
				}
			],
			defaultProps: {
				children: 'departmentChildren',
				label: 'departmentName'
			},
			hasJuese: false,
			company: '',
			tableData: []
		};
	},
	components: {
		deletePopup,
		addBumeng,
		editBumeng,
		addZhanghao,
		deleteZhanghao,
		editZhanghao
	},
	name: 'bumengManage',
	computed: {
		permissionList() {
			return this.$store.state.permissionList;
		},
		isAdmin() {
			return this.$store.state.isadmin && localStorage.getItem('isStaff') == '0';
		}
	},
	created() {
		this._roleList()
		this._staffList();
		this._departmentTree();
		this._getstaffListNumber()
	},
	methods: {
		updataZhanghao() {
			this._staffList()
			this._getstaffListNumber()
			this._departmentTree()
		},
		updataBumeng() {
			this._departmentTree()
			this.$refs.addZhanghao._departmentTree()
			this.$refs.editZhanghao._departmentTree()

		},
		// 获取员工数量	
		async _getstaffListNumber() {
			const res = await staffList({
				disabled: '',
				endDate: '',
				isAsc: 'asc',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				roleId: '',
				searchBody: '',
				startDate: '',
				level: '',
				departmentId: ''
			});
			this.allnumber = res.data.totalCount;
		},
		// 获取角色列表
		async _roleList() {
			const res = await roleList(this.searchInfo);
			// console.log('角色列表',res)
			this.hasJuese = res.data.list.length > 0
		},
		// 删除账号
		async _deleteStaff() {
			const res = await deleteStaff({ staffId: this.deleteId });
			this.$myMessage('删除成功', 'success');
			this.$refs.deleteZhanghao.dialogVisible = false;
			this.updataZhanghao();
		},
		// 获取员工列表
		async _staffList() {
			const res = await staffList(this.searchInfo);
			// console.log('员工列表', res);
			this.tableData = res.data.list;
			this.totalCount = res.data.totalCount;
		},
		// 点击出现修改弹框
		showEdit1(info) {
			this.$refs.editZhanghao._staffDetail(info.userId);
			this.$refs.editZhanghao.drawer = true;
		},
		// 点击出现删除弹框
		showDelete1(id) {
			this.deleteId = id;
			this.$refs.deleteZhanghao.dialogVisible = true;
		},
		// 点击出现添加弹框
		showAdd1() {
			if (!this.hasJuese) return this.$myMessage('请先添加角色','error')
			this.$refs.addZhanghao.drawer = true;
		},
		handleSizeChange(val) {
			this.searchInfo.pageSize = val;
			this._staffList()
			
		},
		handleCurrentChange(val) {
			this.searchInfo.page = val;
			this._staffList()
			
		},
		hideBtnBox() {
			this.showId = '';
		},
		// 点击出现修改按钮
		showBtn(data) {
			console.log(data);
			this.showId = data.departmentId;
		},

		handleNodeClick(data) {
			console.log(data);
			if (data.level) {
				this.searchInfo.level = data.level;
				this.searchInfo.departmentId = data.departmentId;
			} else {
				this.searchInfo.level = '';
				this.searchInfo.departmentId = '';
			}
			
			this._staffList();
		},

		// 获取部门树
		async _departmentTree() {
			const res = await departmentTree();
			console.log('部门树', res.data);
			this.data[0].departmentChildren = res.data;
			// this.tableData = this.fromatInfo(res.data);
			// this.options = this.fromatInfo(res.data)
			// console.log('部门树', this.options);
		},
		// 点击出现删除弹框
		showDelete(id) {
			this.showId = '';
			this.$refs.deletePopup.dialogVisible = true;
			this.deleteId = id;
		},
		// 删除部门
		async _deleteDepartment() {
			const res = await deleteDepartment({
				departmentId: this.deleteId
			});
			this.$myMessage('删除成功', 'success');
			this.updataBumeng();
			this.$refs.deletePopup.dialogVisible = false;
		},
		// 点击出现修改弹框
		showEdit(info) {
			this.showId = '';
			this.$refs.editBumeng.drawer = true;
			this.$refs.editBumeng.id = info.parentDepartmentId;
			this.$refs.editBumeng.ruleForm = {
				departmentDesc: info.departmentDesc, //部门简介
				departmentId: info.departmentId, //部门id
				departmentName: info.departmentName, //部门名称
				level: info.level, //部门层级(上级部门level+1)
				parentDepartmentId: info.parentDepartmentId //上级部门id(一级部门默认为空
			};
		},

		// 点击出现添加弹框
		showAdd(info) {
			this.showId = '';
			this.$refs.addBumeng.drawer = true;
			if (info) {
				this.$refs.addBumeng.ruleForm = {
					departmentDesc: '',
					departmentName: '',
					level: info.level + 1,
					parentDepartmentId: info.departmentId
				};
			}
		}
	}
};
</script>

<style scoped lang="scss">
.box123 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}
.addicon:hover {
}
::v-deep {
	.el-tree-node__children {
		position: relative;
		overflow: inherit;
	}
}
.hidebtnbox {
	display: none;
}
.showbtnbox {
	padding: 0.35em 0;
	display: block;
	position: absolute;
	z-index: 99999;
	width: 8em;
	right: -6em;
	top: 2em;
	background: #ffffff;
	box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #5b6271;
	div {
		padding: 0.5em 1em;
	}
}
::v-deep {
	.el-tree-node__content {
		margin-bottom: 0.5em;
		position: relative;
	}
}

.bumengbox {
	width: 18%;
	// padding: ;
}
.custom-tree-node {
	position: relative;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	padding-right: 8px;
}
::v-deep {
	.pagebox {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin: 6.25em 0 0 1.25em;
		padding: 0 1.25em 0 0;
		.el-input__inner {
			color: #666666;
			width: 3em;
			height: 2.25em;
			line-height: 2.25em;
		}
		.el-select {
			.el-input__inner {
				color: #666666;
				width: 8em;
				height: 2.25em;
				line-height: 2.25em;
			}
		}

		.el-input__icon {
			line-height: 2.25em;
		}
	}
}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 0.9375em;
		height: 1.0625em;
	}
	&.shan {
		background-color: #b3bcc9;
		margin: 0 0 0 1.25em;
		img {
			width: 0.9375em;
			height: 1em;
		}
	}
	&.gen {
		background-color: #fa7e33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.gai {
		background-color: #0091ff;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.875em;
		}
	}
	&.zeng {
		background-color: #9f7eff;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.8125em;
		}
	}
	img {
		width: 1em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	// margin: 0 0 1.25em 0;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.chaxun:active {
	background-color: rgb(234,242,255);
	color: #2F79FF;
	border: 1px solid #2F79FF;
}
.chaxun {
	cursor: pointer;
	margin: 0 0 0 1.25em;
	width: 5.625em;
	height: 2.25em;
	border-radius: 0.25em;
	border: 1px solid #DDDDDD;
	font-weight: 400;
	color: #333333;
	font-family: PingFang-SC-Medium, PingFang-SC;
	span {
		font-size: 0.875em;
	}
}

.tabletop {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
::v-deep {
	.selectbox {
		margin: 0 0 0 2.5em;
		display: flex;
		align-items: center;
		// overflow: hidden;
		span {
			font-size: 0.875em;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			margin: 0 0.6875em 0 0;
		}
		.el-input__inner {
			color: #666666;
			width: 9.75em;
			height: 2.25em;
			line-height: 2.25em;
		}
	}
}

.tablebox {
	background-color: #ffffff;
	padding: 1rem 1.25em;
}
.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div {
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}

.gonghai {
	margin: 0.9375em auto 0;
	display: flex;
	width: calc(100% - 2.5em);
	padding: 1.25em;
	background-color: #ffffff;
}
@media screen and (max-width: 1920px) {
	.gonghai {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.gonghai {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.gonghai {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.gonghai {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.gonghai {
		font-size: 12px;
	}
}
</style>
