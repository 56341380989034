<template>
	<div>
		<el-drawer
			title="我是标题"
			:visible.sync="drawer"
			:direction="direction"
			:before-close="resetForm"
			:with-header="false"
		>
			<div class="titlebox">
				<span>修改部门</span>
				<i @click="resetForm" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
			</div>
			<div class="formbox">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="0"
					class="demo-ruleForm"
				>
					<el-form-item label="" prop="departmentName">
						<div class="inputtitle">
							<span>*</span>
							部门名称
						</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.departmentName" placeholder="请输入部门名称"></el-input>
						</div>
					</el-form-item>

					<el-form-item label="" prop="name">
						<div class="inputtitle" style="margin-top: 25px;">
							上级部门
						</div>
						<el-cascader
						placeholder="若为一级部门,则不选"
						ref="myCascader"
						@change="getChoose"
						v-model="id"
							:options="options"
							:props="{ checkStrictly: true }"
							clearable
						></el-cascader>
					</el-form-item>

					<el-form-item label="" prop="departmentDesc">
						<div class="inputtitle" style="margin-top: 25px;">
							部门简介
						</div>
						<div class="textareabox">
							<el-input
								v-model="ruleForm.departmentDesc"
								type="textarea"
								placeholder="请输入部门简介"
							></el-input>
						</div>
					</el-form-item>
				</el-form>
				<div class="twobtn">
					<div @click="resetForm('ruleForm')">取消</div>
					<div class="save" @click="submitForm('ruleForm')">保存</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { departmentTree, editDepartment } from '@/network/api.js';
export default {
	data() {
		return {
			options: [],
			id: '',
			ruleForm: {
				departmentDesc: '', //部门简介
				departmentId: '',				//部门id
				departmentName: '', //部门名称
				level: 1, //部门层级(上级部门level+1)
				parentDepartmentId: '' //上级部门id(一级部门默认为空
			},
			rules: {
				departmentName: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
				// departmentDesc: [{ required: true, message: '请输入部门简介', trigger: 'blur' }]
			},
			drawer: false,
			direction: 'rtl',
			value1: '',
			wayIndex: 0
		};
	},
	watch: {
		// ruleForm: {
		// 	changeVal(val) {
		// 		console.log("watch----ruleform",val);
		// 	},
		// 	deep: true,
		// 	immediate: true
		// },
		
	},
	created() {
		this._departmentTree();
	},
	
	methods: {

		// 获取选中的部门数据
		getChoose(val) {
			// const lenLength = this.$refs.myCascader.getCheckedNodes()[0].pathLabels.length - 1
			// console.log(this.$refs.myCascader.getCheckedNodes()[0]);
			console.log(val);
			if (val.length < 1) {
				this.ruleForm.parentDepartmentId = ''
				this.ruleForm.level = 1
			} else {
				this.ruleForm.parentDepartmentId = this.$refs.myCascader.getCheckedNodes()[0].value
				this.ruleForm.level = this.$refs.myCascader.getCheckedNodes()[0].level + 1
			}
			
		},
		// 将部门数据转化为可用数据
		fromatInfo(info) {
			let data = info.map(item => {
				let data1 = {
					value: item.departmentId,
					label: item.departmentName,
					parentDepartmentId: item.parentDepartmentId,
					level: item.level,
					children: item.departmentChildren
				}
				if (item.departmentChildren && item.departmentChildren.length > 0) {
					data1.children = this.fromatInfo(item.departmentChildren)
				}
				return data1
			})
			return data
		},
		// 添加部门
		async _editDepartment() {
			if (this.ruleForm.departmentId === this.ruleForm.parentDepartmentId) {
				return this.$myMessage("无法修改",'error')
			}
			console.log(this.ruleForm);
			// return
			const res = await editDepartment(this.ruleForm);
			this.$myMessage('编辑成功', 'success');
			this._departmentTree()
			this.drawer = false;
			this.$emit('updataInfo');
		},
		// 获取部门树
		async _departmentTree() {
			const res = await departmentTree();
			// console.log('部门树', res.data);
			this.options = this.fromatInfo(res.data)
			// console.log('部门树', this.options);
			
			// res.data.forEach()
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this._editDepartment();
				} else {
					return false;
				}
			});
		},
		resetForm() {
			this.$refs['ruleForm'].resetFields();
			
			this.drawer = false;
			this.ruleForm = {
				departmentDesc: '', //部门简介
				departmentName: '', //部门名称
				level: 1, //部门层级(上级部门level+1)
				parentDepartmentId: '' //上级部门id(一级部门默认为空
			}
			this.id = ''
		},
		// 选择跟进方式
		chooseWay(index) {
			this.wayIndex = index;
		},

	}
};
</script>

<style scoped lang="scss">
::v-deep {
	.el-form-item__content {
		line-height: 20px;
	}
	.inputbox {
		.el-input__inner {
			width: 600px !important;
			height: 40px !important;
		}
	}

	.el-form-item {
		margin: 0;
	}
}
::v-deep {
	.choosetime {
		.el-input__inner {
			width: 400px !important;
			height: 40px !important;
		}
	}
}

.formbox {
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4d91ff;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #eeeeee;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
.inputtitle {
	margin: 0 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #ff0000;
		margin: 0 10px 0 0;
	}
}
.oneway {
	cursor: pointer;
	margin: 0 30px 10px 0;
	display: flex;
	align-items: center;
	div {
		width: 14px;
		height: 14px;
		background: #ffffff;
		border: 1px solid #cccccc;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 10px 0 0;
		&.activeway {
			border: 1px solid #2e77f6;
		}
		p {
			width: 8px;
			background-color: #2e78f6;
			height: 8px;
			border-radius: 50%;
		}
	}
}
.allway {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 0 0 17px;
}
::v-deep {
	.el-input__inner {
		width: 520px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}
textarea:focus {
	border: 1px solid #4d91ff;
}
::v-deep {
	textarea {
		font-size: 14px;
		outline: none;
		padding: 13px 20px;
		width: 100%;
		height: 150px;
		background: #ffffff;
		border-radius: 4px;
		border: 1px solid #eeeeee;
		max-width: 100%;
		min-width: 100%;
		min-height: 100px !important;
	}
}

.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-drawer {
		width: 700px !important;
		border-radius: 10px 0px 0px 10px !important;
	}
}
</style>
